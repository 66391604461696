import { LocalDate } from "@js-joda/core";
import { ListingFilterForm } from "../listings/types/ListingFilterForm";
import { UserListQuery } from "../users/types/userListQuery";

const adminQueryKeys = {
  all: ["admin"] as const,
  adminUser: () => [...adminQueryKeys.all, "adminUser"] as const,
  adminSearches: () => [...adminQueryKeys.all, "adminSearch"] as const,
  adminSearchByQuery: (query: string) =>
    [...adminQueryKeys.adminSearches(), { query }] as const,
  featuresFilterDataList: () =>
    [...adminQueryKeys.all, "featuresFilterDataList"] as const,
  dealerDetails: () => [...adminQueryKeys.all, "dealerDetails"] as const,
  dealerDetailById: (id: string) =>
    [...adminQueryKeys.dealerDetails(), { id }] as const,
  dealsSearch: () => [...adminQueryKeys.all, "dealsSearch"] as const,
  dealerList: (dealerGroupIds: number[], provinceIds: number[]) =>
    [
      ...adminQueryKeys.all,
      "dealerList",
      { dealerGroupIds, provinceIds },
    ] as const,
  dealInformation: () => [...adminQueryKeys.all, "dealInformation"] as const,
  dealInformationById: (id: string) =>
    [...adminQueryKeys.dealInformation(), { id }] as const,
  appraisals: () => [...adminQueryKeys.all, "appraisals"] as const,
  appraisalPreviewDetails: () =>
    [...adminQueryKeys.all, "appraisalPreview"] as const,
  appraisalPreviewById: (id: string) =>
    [...adminQueryKeys.appraisalPreviewDetails(), { id }] as const,
  appraisalImageList: (id: string) =>
    [...adminQueryKeys.appraisalPreviewById(id), { id }] as const,
  appraisalSearchByVin: (sourceId: number, vinQuery: string) =>
    [
      ...adminQueryKeys.appraisals(),
      "appraisalSearch",
      { sourceId, vinQuery },
    ] as const,
  dealCustomerCreditReport: () =>
    [...adminQueryKeys.all, "dealCustomerCreditReport"] as const,
  dealCustomerCreditReportsById: (id: string) =>
    [...adminQueryKeys.dealCustomerCreditReport(), { id }] as const,
  dealDocuments: (dealId: string) =>
    [...adminQueryKeys.all, "dealDocuments", { dealId }] as const,
  availableDocumentsForDealId: (dealId: string) =>
    [...adminQueryKeys.all, "availableDocumentsForDealId", { dealId }] as const,
  dealTodos: (dealId: string) =>
    [...adminQueryKeys.all, "dealTodos", { dealId }] as const,
  productsByDealId: (dealId: string) =>
    [...adminQueryKeys.dealInformationById(dealId), "products"] as const,
  productConfigurationsByDealId: (dealId: string, productId: string) =>
    [
      ...adminQueryKeys.all,
      "productConfigurationsByDealId",
      { dealId, productId },
    ] as const,
  availableProductsByDealId: (dealId: string) =>
    [
      ...adminQueryKeys.dealInformationById(dealId),
      "available-products",
    ] as const,
  transactionsByDealId: (dealId: string) =>
    [
      ...adminQueryKeys.dealInformationById(dealId),
      "transaction-list",
    ] as const,
  adminTradeInContext: () => [...adminQueryKeys.all, "tradeInContext"] as const,
  todos: () => [...adminQueryKeys.all, "todos"] as const,
  tradeInVerificationItemsByDealAndTradeInId: (
    dealId: string,
    tradeInId: string
  ) =>
    [
      ...adminQueryKeys.all,
      "tradeInVerificationItems",
      { dealId, tradeInId },
    ] as const,
  payoutEmbedUrl: (dealId: string | null) =>
    [...adminQueryKeys.all, "payoutEmbedUrl", { dealId }] as const,
  customerPayoutInformation: (dealId: string) =>
    [...adminQueryKeys.all, "customerPayoutInformation", { dealId }] as const,
  searchAppraisals: () => [...adminQueryKeys.all, "searchAppraisals"] as const,
  userRolesAndPermissions: () =>
    [...adminQueryKeys.all, "userRolesAndPermissions"] as const,
  dealerGroupsAndDropOffLocations: () =>
    [...adminQueryKeys.all, "dealerGroupsAndDropOffLocations"] as const,
  userList: () => [...adminQueryKeys.all, "userList"] as const,
  userListByQuery: (query: UserListQuery) =>
    [...adminQueryKeys.userList(), query] as const,
  userDetail: (adminId: string) =>
    [...adminQueryKeys.all, "userDetail", { adminId }] as const,
  dealTransactionSecret: (dealId: string | null, state: string) =>
    [
      ...adminQueryKeys.all,
      "dealTransactionSecret",
      { dealId, state },
    ] as const,
  gapPromoCodes: () => [...adminQueryKeys.all, "gapPromoCodes"] as const,
  tireAndRimCoverages: (dealId: string) =>
    [...adminQueryKeys.all, "tireAndRimCoverages", { dealId }] as const,
  dropOffLocationAppointments: () =>
    [...adminQueryKeys.all, "dropOffLocationAppointments"] as const,
  dropOffLocationAppointmentByTimeFrameAndLocations: (
    startDate: LocalDate,
    endDate: LocalDate,
    locationIds: string[]
  ) =>
    [
      ...adminQueryKeys.dropOffLocationAppointments(),
      {
        startDate: startDate.toString(),
        endDate: endDate.toString(),
        locationIds,
      },
    ] as const,
  kijijiListings: () => [...adminQueryKeys.all, "kijijiListings"] as const,
  kijijiListingsList: (params: {
    orderBy?: string;
    desc?: boolean;
    filters: ListingFilterForm;
  }) => [...adminQueryKeys.kijijiListings(), params] as const,
  kijijiListingByAdId: (adId: number) =>
    [...adminQueryKeys.kijijiListings(), { adId }] as const,
  kijijiListingMakes: () =>
    [...adminQueryKeys.all, "kijijiListingMakes"] as const,
  kijijiListingBodyStyles: () =>
    [...adminQueryKeys.all, "kijijiListingBodyStyles"] as const,
};

export default adminQueryKeys;
